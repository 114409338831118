.articalFirst {
    width: 100vw;
    background-color: #fff;
    padding: 0 16px;
    box-sizing: border-box;
    padding-bottom: 50px;
}

.backIcon {
    position: absolute;
    top: 18px;
    left: 12px;
    z-index: 9999;

}

.title {
    font-size: 18px;
    /* line-height: 50px; */
    padding-top: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    font-weight: 600;
    margin-left: 20px;
    /* text-align: center; */
}

.titleSecond {
    color: rgb(255, 102, 0);
    line-height: 30px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
}

.content {
    font-size: 14px;
    line-height: 30px;
}

.type {
    font-size: 17px;
    font-weight: bold;
    width: 100px;
    height: 40px;
    color: #fff;
    text-align: center;
    background-color: #aa1d14;
    line-height: 40px;
    margin: 16px 0;
}

.titleThird {
    color: #aa1d14;
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
}

.titleFour {
    color: #003B9C;
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
}