.home {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home :global(.adm-card-body) {
    padding: 0 !important;
}

.pickers :global(.adm-picker-header) {
    display: none;
}

.pickers :global(.adm-picker-view) {
    display: none !important;
}

.header {
    width: 100%;
    height: 210px;
    min-height: 210px;
    padding: 0 16px;
    box-sizing: border-box;
    background: url(../../components/images/homeBg.png) no-repeat;
    background-size: 100%;
}

.swiperContent {
    min-height: 210px;
}

.peopleContent {
    width: 100%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    padding: 16px;
    box-sizing: border-box;
    min-height: 102px;
}

.people {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.peopleNameBox {
    display: flex;
    align-items: flex-end;
}

.peopleName {
    color: rgba(34, 34, 34, 1);
    font-size: 17px;
    font-weight: bold;
}

.peopleNameChange {
    width: 54px;
    height: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid rgba(252, 156, 65, 1);
}

.changeIcon {
    width: 16px;
    height: 16px;
    background: url(../../components/images/change.png) no-repeat;
    background-size: 100%;
}

.changeText {
    color: #FC9C41;
    font-size: 14px;
}

.visitCard {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.visitCardTitle {
    width: 70px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 33px 0px 0px 34px;
    background-color: rgba(14, 188, 106, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.visitCardValue {
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 0 33px 33px 0;
    text-align: center;
    color: rgba(14, 188, 106, 1);
    border: 1px solid rgba(238, 238, 238, 1);
}

.peopleNameChange {
    width: 54px;
    height: 20px;
    border-radius: 2px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 2px;
    box-sizing: border-box;
}

.titleContent {
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    background-color: rgba(255, 255, 255, 1);
    padding: 16px;
}

.peopleContentLeft {
    flex: 1;
}

.peopleContenRight {
    width: 70px;
    height: 70px;
}

.doctorIcon {
    position: absolute;
    right: 0;
    top: -80px;
    width: 95px;
    height: 95px;
    background: url(../../components/images/homeDoctor.png) no-repeat;
    background-size: 100%;
}

.actionTabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 16px; */
    box-sizing: border-box;
}

.actionTabsItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.yuyueItem {
    width: 42px;
    height: 42px;
    background: url(../../components/images/yuyue.png) no-repeat;
    background-size: 100%;
}

.lianxiItem {
    width: 42px;
    height: 42px;
    background: url(../../components/images/lianxi.png) no-repeat;
    background-size: 100%;
}

.adressItem {
    width: 42px;
    height: 42px;
    background: url(../../components/images/adress.png) no-repeat;
    background-size: 100%;
}

.zhensuoItem {
    width: 42px;
    height: 42px;
    background: url(../../components/images/zhensuo.png) no-repeat;
    background-size: 100%;
}

.actionTabsItemTitle {
    color: rgba(16, 16, 16, 1);
    font-size: 15px;
    font-weight: bold;
    margin-top: 12px;
}

.centerImg {
    width: 100%;
    height: 100px;
    height: 50px;
    line-height: 50px;  
    background-color: #fff;
    position: relative;
    text-align: center;
    color: rgba(255, 0, 0, 1);
    font-size: 14px;
    top: -12px;
}

.more {
    position: absolute;
    right: 16px;
    color: #999999;
    font-size: 14px;
    top: 12px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    height: 45px;
    padding: 20px 0 0px 0;
}

.guanliItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(137.51deg, rgba(255, 191, 27, 1) -0.98%, rgba(252, 156, 65, 1) 98.6%);
}

.recordItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(-46.58deg, rgba(42, 124, 246, 1) 2.45%, rgba(117, 178, 213, 1) 101.97%);
}

.couponsItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(134.77deg, rgba(14, 188, 106, 1) 0.9%, rgba(14, 188, 133, 1) 100%);
}

.settingItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(137.51deg, rgba(255, 191, 27, 1) -0.98%, rgba(252, 156, 65, 1) 98.6%);
}

.noPeople {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    color: #999;
    font-size: 17px;
    font-weight: bold;
}