.myVisitorsList {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  display: flex;
  flex-direction: column;
}
.backIcon {
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 9999;
}

.myVisitorsListContent {
  overflow: scroll;
  height: 94%;
  padding-bottom: 30px;
}

.myVisitorsList :global(.adm-card-body) {
  padding: 0;
}

.myVisitorsListContent :global(.adm-dialog-button) {
  font-size: 17px;
}

.myVisitorsListContent :global(.adm-auto-center-content) {
  font-size: 17px;
  font-weight: bold;
}

.myVisitorsListContent :global(.adm-dialog-button:last-child) {
  font-size: 17px !important;
}

.myVisitorsList :global(.adm-tabs) {
  width: 100%;
  overflow: hidden;
}

.myVisitorsList :global(.adm-capsule-tabs) {
  height: 62px !important;
}

.myVisitorsList :global(.adm-card) {
  padding: 0 !important;
}
/* 
.myVisitorsListContent :global(.adm-tabs-content) {
  width: 100vw;
  height: 84%;
  overflow: scroll;
} */

.myVisitorsList :global(.adm-tabs-tab-active) {
  color: #41bb67;
}

.myVisitorsList :global(.adm-tabs-tab-line) {
  background: #41bb67;
}

.myVisitorsList :global(.adm-tabs-tab) {
  font-size: 14px;
  padding: 12px 0 10px;
}

.myVisitorsList :global(.adm-tabs-tab-wrapper) {
  height: 40px;
}

.myVisitorsListContent :global(.adm-card) {
  border-radius: 5px;
  margin: 10px 10px 0 10px;
}

.myVisitorsListContentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #eeeeee;
  height: 50px;
  padding: 0 16px;
}

.myVisitorsListContentMain {
  padding: 16px;
}

.myVisitorsListContentTime {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myVisitorsListContentType {
  color: #ffb145;
}

.myVisitorsListContentMainContent {
  display: flex;
  line-height: 30px;
}

.myVisitorsListContentTimes{
   width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myVisitorsListContentMainBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myVisitorsListContentRemark {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.myVisitorsListContentLeft {
  width: 47%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.myVisitorsListContentRight {
  width: 47%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myVisitorsListContentMain {
  /* width: 100%; */
}

.myVisitorsListContentButton {
  color: #ff2020;
  border: 1px solid #ff2020;
  border-radius: 5px;
  padding: 2px 6px;
}

.dialogMain :global(.adm-auto-center-content) {
  font-size: 17px;
  font-weight: bold;
  color: #101010;
}

.dialogMain :global(.adm-dialog-footer) {
  display: flex;
}

.dialogMain :global(.adm-dialog-action-row) {
  width: 144px;
}

/* .dialogMain :global(.adm-dialog-action-row :nth-of-type(1)) {
  color: red;
}

.dialogMain :global(.adm-dialog-action-row :nth-of-type(2)) {
  color: rgb(115, 182, 39);
} */
