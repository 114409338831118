.addPatientPeopleContent {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.backIconBox {
  position: relative;
  height: 20px;
}

.backIcon {
  position: fixed;
  top: 8px;
  left: 10px;
  z-index: 9999;
}

.cardOne :global(.adm-card-body) {
  padding: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.cardOne :global(.adm-list-body) {
  border-radius: 6px;
}

.addPatientPeopleContent :global(.adm-input) {
  font-size: 15px;
}

.cardOne :global(.adm-input-element) {
  text-align: right;
}

.cardOne :global(.adm-list-item-content) {
  height: 50px;
  padding: 0 16px;
  border-top: none;
}

.addPatientPeopleContent :global(.adm-list-item) {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 0;
  height: 50px;
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #cccccc;
  font-size: 15px !important;
}

.addPatientPeopleContent :global(.adm-form-item-label) {
  font-size: 15px;
  color: #333333;
}

.addPatientPeopleContent :global(.adm-input-element) {
  font-size: 15px;
  color: #333333;
}

.cardOne {
  margin: 10px;
  /* height: 89%; */
  overflow: scroll;
}

.cardOne :global(.adm-list-default) {
  border-radius: 5px;
}

.cardOne :global(.adm-list-item) {
  padding: 0;
}

.addPatientPeopleContentCard {
  padding: 0 16px !important;
  margin: 10px 10px 0 10px !important;
}

.addPatientPeopleContent :global(.adm-list-item) {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 0;
  height: 50px;
}

.addPatientPeopleContent :global(.adm-form-item-label) {
  font-size: 15px;
  color: #333333;
  width: 100%;
}

.addPatientPeopleContent :global(.adm-input) {
  font-size: 15px;
}

.addPatientPeopleContent :global(.adm-list-item-content-main) {
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
  text-align: right !important;
}

.addPatientPeopleContent :global(.adm-form-item-feedback-error) {
  display: none;
}

.addPatientPeopleContent :global(.adm-form-item-feedback-error) {
  text-align: right;
}

.addPatientPeopleContent :global(.adm-radio-content) {
  font-size: 15px;
  margin-right: 4px;
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #cccccc;
  font-size: 15px !important;
}

.bottomButton {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #ffffff; */
  /* position: fixed;
  bottom: 0; */
}

.bottomButtonText {
  width: 300px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}


.default {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  height: 50px;
  margin-top: 10px;
  padding: 0 20px;
  box-sizing: border-box;
}