.clinicOverview {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clinicOverview :global(.adm-card-body) {
  padding: 0 !important;
}

.header {
  height: 187px;
}

.swiperContent {
  min-height: 187px;
}

.titleContent {
  width: 94%;
  line-height: 20px;
  border-radius: 9px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  top: -20px;
  padding: 16px;
  margin: 0 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.titleTagContent {
  margin-top: 10px;
}

.titleTopContent {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.titleBottomContentOne {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}
.titleBottomContent,
.titleBottomContentOne {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.titleTag {
  width: 100%;
  margin-right: 6px;
}

.titleBottomLeftTextPhone {
  width: 88%;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleBottomLeftText {
  flex: 1;
}

.titleBottomLeftTextMain,
.titleBottomLeftTextPhone {
  width: 88%;
  margin-right: 6px;
}

.titleBottomLeftTextMain {
  display: flex;
}

.titleBottomLeftTextMainTitle {
  min-width: 25%;
}

.titleTagText {
  border-radius: 26px;
  background-color: #ffeed8;
  color: #fc9c41;
  font-size: 12px;
  text-align: center;
  padding: 4px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
}

.titleBottomRightContent {
  display: flex;
}

.titleBottomRightTextOne {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.titleBottomRightText {
  display: flex;
  align-items: center;
}

.centerContentProduce {
  width: 100%;
  height: 60px;
  display: flex;
  margin-top: -10px;
}

.clinicOverviewContent {
  width: 94%;
  border-radius: 9px;
  background: #ffffff;
  padding: 1px 0px;
  padding-bottom: 10px;
  margin-top: -5px;
}

.clinicOverviewContentTitle::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #0ebc6a;
  position: relative;
  left: -16px;
}

.clinicOverviewContentTitle {
  font-size: 17px;
  color: #101010;
  padding: 16px 16px 14px 16px;
}

.clinicOverviewContentMain {
  font-size: 15px;
  color: #333333;
  padding: 0 16px 10px 16px;
}

.popPhone {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #41bb67;
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
}

.popCall {
  font-size: 18px;
  height: 58px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popBack {
  height: 20px;
  background-color: #f7f7f7;
}

.popCancel {
  font-size: 18px;
  height: 58px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: center;
}
