.coupons {
    /* width: 100%; */
    position: relative;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backIcon {
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 9999;
}

.coupons :global(.adm-tabs-tab-active) {
    color: #41bb67 !important;
}

.coupons :global(.adm-tabs-tab-line) {
    background: #41bb67 !important;
    width: 42px;
    margin: 0 auto;
}


.coupons :global(.adm-tabs-tab) {
    margin: 0;
    /* width: 100%; */
    text-align: center;
}

.coupons :global(.adm-tabs-header) {
    border: none !important;
}

.couponsList {
    margin-top: 20px;
}

.coupons :global(.adm-tabs) {
    width: 100%;
    overflow: hidden;
}

.coupons :global(.adm-tabs-content) {
    width: 100vw;
    height: 92%;
    overflow: scroll;
}

.coupons :global(.adm-tabs-tab-active) {
    color: #41bb67;
}

.coupons :global(.adm-tabs-tab-line) {
    background: #41bb67;
}

.coupons :global(.adm-tabs-tab) {
    font-size: 14px;
    /* padding: 12px 0 10px; */
}

.coupons :global(.adm-tabs-tab-wrapper) {
    height: 40px;
    display: flex;
    justify-content: center;
}

.couponItem {
    position: relative;
    display: flex;
    align-items: center;
    width: 94%;
    height: 88px;
    line-height: 20px;
    border-radius: 10px;
    background: linear-gradient(-45.43deg, rgba(255, 191, 27, 0.16), rgba(252, 156, 65, 0.3));
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    margin: 0 auto;
    padding-left: 14px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.couponItemLeft {
    width: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.couponItemRight {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.couponItemLeftTop {
    width: 100%;
    color: rgba(247, 120, 0, 1);
    /* margin-bottom: 8px; */
}

.couponItemLeftTopValue {
    font-size: 28px;
    font-weight: bold;
}

.freeCoupons {
    font-size: 18px;
    font-weight: bold;
}

.couponItemRightTop {
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    color: rgba(86, 31, 0, 1);
    font-size: 17px;
    margin-bottom: 14px;
}

.couponItemLeftBottom,
.couponItemRightBottom {
    width: 100%;
}

.detail {
    padding: 25px 20px 20px;
    text-align: left;
}

.detailTitle {
    color: rgba(16, 16, 16, 1);
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
}

.detailContent {
    color: rgba(102, 102, 102, 1);
    font-size: 13px;
    margin-bottom: 15px;
}

.know {
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background: linear-gradient(137.51deg, rgba(255, 191, 27, 1) -0.98%, rgba(252, 156, 65, 1) 98.6%);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    margin: 0 auto 5px;
}

.detailType {
    text-align: center;
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    font-weight: bold;
}

.detailIssue {
    color: rgba(255, 0, 0, 1);
    font-size: 28px;
    text-align: center;
    margin: 6px 0 30px;
}

.typeIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.coupons :global(.adm-card-body) {
    padding: 0;
}

.coupons :global(.adm-dialog-button) {
    font-size: 17px;
}

.coupons :global(.adm-auto-center-content) {
    font-size: 17px;
    font-weight: bold;
}

.coupons :global(.adm-dialog-button:last-child) {
    font-size: 17px !important;
}

.coupons :global(.adm-tabs) {
    width: 100%;
    overflow: hidden;
}

.coupons :global(.adm-capsule-tabs) {
    height: 62px !important;
}

.coupons :global(.adm-card) {
    padding: 0 !important;
}

/* 
.myVisitorsListContent :global(.adm-tabs-content) {
  width: 100vw;
  height: 84%;
  overflow: scroll;
} */

.coupons :global(.adm-tabs-tab-active) {
    color: #41bb67;
}

.coupons :global(.adm-tabs-tab-line) {
    background: #41bb67;
}

.coupons :global(.adm-tabs-tab) {
    font-size: 14px;
    padding: 12px 0 10px;
}

.coupons :global(.adm-card) {
    border-radius: 5px;
    margin: 10px 10px 0 10px;
}