.container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(134.77deg, rgba(14, 188, 106, 1) 0.9%, rgba(14, 188, 133, 1) 100%);
}

.bg {
    width: 84%;
    height: 430px;
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 63px;
}

.headIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    padding: 10px;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    background-color: #fff;
    box-sizing: border-box;
}

.patientName {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    font-weight: bold;
}

.patientCard {
    color: rgba(51, 51, 51, 1);
    font-size: 15px;
    margin-top: 2px;
}

.codeCard {
    position: relative;
    width: 150px;
    height: 151px;
    margin: 34px auto;
}

.threeIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: 2px solid #29BE78;
    border-left: 2px solid #29BE78;
}

.info {
    display: flex;
    text-align: left;
    padding-left: 33px;
    box-sizing: border-box;
    color: rgba(51, 51, 51, 1);
    font-size: 15px;
    margin-top: 16px;
}

.info span:first-child {
    width: 95px;
}

.backIcon {
    position: absolute;
    top: 16px;
    left: 10px;
    z-index: 9999;
}