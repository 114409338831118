.container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* padding-bottom: 20px; */
    /* margin-top: 20px; */
}
.backIconBox {
    position: relative;
    height: 20px;
}

.backIcon {
    position: fixed;
    top: 8px;
    left: 10px;
    z-index: 9999;
}
.bottomList {
    width: 94%;
    /* height: 100%; */
    position: relative;
    padding: 0px 10px;
    background: #ffffff;
    /* overflow-y: scroll; */
    border-radius: 8px;
    padding: 8px 0px;
    /* margin-top: 30px; */
    margin: 20px auto; 
    box-sizing: border-box;
}

.bottomContent {
    padding-bottom: 15px;
    margin: 16px 16px 0 16px;
    display: flex;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.bottomContentLeftImg {
    width: 66px;
}

.bottomContentRightMainTitLe {
    display: flex;
    height: 25px;
    margin-bottom: 2px;
    /* align-items: center; */
}

.doctorProduce {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.doctorName {
    color: #101010;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
    width: 36%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
}

.doctorProduce :nth-of-type(1) {
    color: #101010;
    font-size: 14px;
}

.bottomContentRightMainContent {
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
}

.bottomContentRightMain {
    flex: 1;
}