.visitorsList {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.backIconBox {
  position: relative;
  height: 24px;
}
.backIcon {
  position: fixed;
  top: 6px;
  left: 10px;
  z-index: 9999;
}
.visitorsListContent {
  overflow: scroll;
  height: 100%;
  padding-bottom: 56px;
}

.visitorsListContentList {
  padding: 10px 10px 0 10px;
  height: 90%;
  /* overflow: scroll; */
}

.visitorsListContentList :global(.adm-card) {
  height: 80px;
  background-color: #fff;
}

.visitorsListContentList :global(.adm-swipe-action) {
  height: 80px;
  border-radius: 5px;
}

.name,
.phone {
  font-size: 15px;
  color: #333333;
}

.name {
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.visitorsListContentList :global(.adm-button) {
  padding: 0;
  width: 60px;
}

.bottomButton {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.bottomButtonText {
  width: 343px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}

.dialogMain :global(.adm-auto-center-content) {
  font-size: 17px;
  font-weight: bold;
  color: #101010;
}

.dialogMain :global(.adm-dialog-footer) {
  display: flex;
}

.dialogMain :global(.adm-dialog-action-row) {
  width: 144px;
}

.defaultPeop {
  width: 44px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}