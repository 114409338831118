.home {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  align-items: center;
}

.home :global(.adm-card-body) {
  padding: 0 !important;
}

.pickers :global(.adm-picker-header) {
  display: none;
}

.pickers :global(.adm-picker-view) {
  display: none !important;
}

.header {
  width: 100%;
  height: 210px;
  min-height: 210px;
  padding: 0 16px;
  box-sizing: border-box;
  background: url(../../components/images/homeBg.png) no-repeat;
  background-size: 100%;
}

.swiperContent {
  min-height: 210px;
}

.peopleContent {
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  padding: 16px;
  box-sizing: border-box;
  min-height: 102px;
}

.people {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.peopleNameBox {
  display: flex;
  align-items: flex-end;
}

.peopleName {
  color: rgba(34, 34, 34, 1);
  font-size: 17px;
  font-weight: bold;
}

.peopleNameChange {
  width: 54px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(252, 156, 65, 1);
}

.changeIcon {
  width: 16px;
  height: 16px;
  background: url(../../components/images/change.png) no-repeat;
  background-size: 100%;
}

.changeText {
  color: #FC9C41;
  font-size: 14px;
}

.visitCard {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.visitCardTitle {
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 33px 0px 0px 34px;
  background-color: rgba(14, 188, 106, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.visitCardValue {
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 0 33px 33px 0;
  text-align: center;
  color: rgba(14, 188, 106, 1);
  border: 1px solid rgba(238, 238, 238, 1);
}

.peopleNameChange {
  width: 54px;
  height: 20px;
  border-radius: 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 2px;
  box-sizing: border-box;
}

.titleContent {
  width: 100%;
  border-radius: 30px 30px 0px 0px;
  background-color: rgba(255, 255, 255, 1);
  padding: 16px;
  box-sizing: border-box;
}

.peopleContentLeft {
  flex: 1;
}

.peopleContenRight {
  width: 70px;
  height: 70px;
}

.doctorIcon {
  position: absolute;
  right: 0;
  top: -80px;
  width: 95px;
  height: 95px;
  background: url(../../components/images/homeDoctor.png) no-repeat;
  background-size: 100%;
}

.actionTabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
}

.actionTabsItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.yuyueItem {
  width: 42px;
  height: 42px;
  background: url(../../components/images/yuyue.png) no-repeat;
  background-size: 100%;
}

.lianxiItem {
  width: 42px;
  height: 42px;
  background: url(../../components/images/lianxi.png) no-repeat;
  background-size: 100%;
}

.adressItem {
  width: 42px;
  height: 42px;
  background: url(../../components/images/adress.png) no-repeat;
  background-size: 100%;
}

.zhensuoItem {
  width: 42px;
  height: 42px;
  background: url(../../components/images/zhensuo.png) no-repeat;
  background-size: 100%;
}

.actionTabsItemTitle {
  color: rgba(16, 16, 16, 1);
  font-size: 15px;
  font-weight: bold;
  margin-top: 12px;
}

.centerImg {
  width: 94%;
  height: 100px;
  min-height: 100px;
  border-radius: 16px;
  background-color: pink;
  position: relative;
  top: -12px;
}

.more {
  position: absolute;
  right: 16px;
  color: #999999;
  font-size: 14px;
  top: 12px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  height: 45px;
  padding: 20px 0 0 0;
}

.titleTagContent {
  margin-top: 10px;
}

.titleTopContent {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.titleBottomContent {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.titleTag,
.titleBottomLeftText {
  width: 70%;
  margin-right: 6px;
}

.titleTagText {
  border-radius: 26px;
  background-color: #ffeed8;
  color: #fc9c41;
  font-size: 12px;
  text-align: center;
  padding: 4px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
}

.titleTagRightText {
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
}

.titleBottomRightContent {
  display: flex;
}

.titleBottomRightTextOne {
  border-right: 1px solid rgba(238, 238, 238, 1);
  padding-right: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.titleBottomRightText {
  padding-left: 10px;
}

.centerContentProduce {
  width: 100%;
  height: 60px;
  display: flex;
  margin-top: -10px;
}

.noPeople {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: #999;
  font-size: 17px;
  font-weight: bold;
}

.centerContentLeftProduce {
  /* width: 165px; */
  width: 50%;
  height: 60px;
  background: url(../../components/images/centerBackLeft.png) no-repeat;
  margin-left: 16px;
  background-size: 100% 100%;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.centerContentRightProduce {
  /* width: 165px; */
  width: 50%;
  height: 60px;
  background: url(../../components/images/centerBackRight.png) no-repeat;
  margin-right: 16px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.centerBackIcon {
  position: absolute;
  right: 0;
  top: 10px;
}

.bottomList {
  width: 94%;
  /* height: 100px; */
  position: relative;
  top: -20px;
  margin: 10px 10px 0 10px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 0px;
}

.bottomList :global(.adm-tabs-tab-active) {
  color: #41bb67 !important;
}

.bottomList :global(.adm-tabs-tab-line) {
  background: #41bb67 !important;
}

.bottomList :global(.adm-tabs-tab-wrapper) {
  padding: 0 16px;
}

.bottomList :global(.adm-tabs-tab) {
  margin: 0;
}

.bottomContent {
  padding-bottom: 15px;
  margin: 16px 16px 0 16px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.bottomContentLeftImg {
  width: 66px;
}

.bottomContentRightMainTitLe {
  display: flex;
  height: 25px;
  margin-bottom: 2px;
  /* align-items: center; */
}

.doctorProduce {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doctorName {
  color: #101010;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  width: 36%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
}

.bottomContentRightMainTitLeArtical {
  color: #101010;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  width: 100%;
  margin-right: 4px;
  height: 25px;
  box-sizing: border-box;
  margin-bottom: 2px;
  overflow: hidden;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all; */
}

.doctorProduce :nth-of-type(1) {
  color: #101010;
  font-size: 14px;
}

.bottomContentRightMainContent {
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.bottomContentRightMainContentTwo {
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  margin-top: 10px;
}

.bottomContentRightMain {
  flex: 1;
}

.popPhone {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #41bb67;
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
}

.popCall {
  font-size: 18px;
  height: 58px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popBack {
  height: 20px;
  background-color: #f7f7f7;
}

.popCancel {
  font-size: 18px;
  height: 58px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: center;
}