.appointmentTreatmentPeopleContent {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.cardOne :global(.adm-card-body) {
  padding: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.backIconBox {
  position: relative;
  height: 20px;
}

.backIcon {
  position: fixed;
  top: 8px;
  left: 10px;
  z-index: 9999;
}

.appointmentTreatmentPeopleContent :global(.adm-input) {
  font-size: 15px;
}

.appointmentTreatmentPeopleContent :global(.adm-input-element) {
  font-size: 15px;
}

.appointmentTreatmentPeopleContent :global(#appointmentDate) {
  color: #0ebc6a;
}

.appointmentTreatmentPeopleContent :global(.adm-text-area-element) {
  font-size: 15px !important;
}

.cardOne :global(.adm-input-element) {
  text-align: right;
}

.cardOne {
  margin: 10px;
  height: 89%;
  overflow: scroll;
}

.cardOne :global(.adm-list-body) {
  border-radius: 6px;
}

/* .cardOne :global(.adm-list-default) {
  border-radius: 5px;
} */

.cardOne :global(.adm-list-item-content) {
  height: 50px;
  padding: 0 16px;
  border-top: none;
  display: flex;
  align-items: center;
}

.cardOne :global(.adm-list-item) {
  padding: 0;
}

.appointmentTreatmentPeopleContentCard {
  padding: 0 16px !important;
  margin: 10px 10px 0 10px !important;
}

.appointmentTreatmentPeopleContent :global(.adm-list-item) {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 0;
  height: 50px;
}

.appointmentTreatmentPeopleContent :global(.adm-form-item-label) {
  font-size: 15px;
  color: #333333;
}

::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: #cccccc;
  font-size: 15px !important;
}

.rightContent {
  width: 80%;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-right: 12px;
  text-align: right !important;
}

.userMessage {
  font-size: 15px;
  color: #333333;
  padding: 16px;
}

.noUserMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 15px;
  min-height: 78px;
}

.treatmentPeopleContent {
  background-color: #ffffff;
  /* padding: 16px; */
  border-radius: 5px;
  margin-top: 10px;
}

.treatmentPeople {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.bottomButton {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.bottomButtonText {
  width: 343px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}
