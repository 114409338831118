.appointmentTimeCardContent {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.backIcon {
  position: absolute;
  top: 44px;
  left: 16px;
  z-index: 9999;
}

.container {
  height: 92%;
  overflow: scroll;
}

.appointmentTimeCardContentTabsMain {
  background: #fff;
  margin: 10px 10px 0 10px;
  display: flex;
  border-radius: 5px;
}

.appointmentTimeCardContentRightIcon {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointmentTimeCardContentTabs {
  width: 88%;
  height: 110px;
  margin-right: 14px;
}

.appointmentTimeCardContentRightIcon {
  width: 10%;
  box-shadow: -2px 0px 2px 0px rgba(252, 252, 252, 1);
}

.appointmentTimeCardContentTabs :global(.adm-jumbo-tabs-tab-wrapper) {
  width: 34px;
  height: 90px;
  background: #f4f4f4;
  margin: 10px 6px 10px 10px;
  border-radius: 5px;
}

.appointmentTimeCardContentTabs :global(.adm-jumbo-tabs-tab) {
  padding: 0;
  width: 100%;
}

.appointmentTimeCardContentTabs :global(.adm-jumbo-tabs-tab-description) {
  color: #101010;
  width: 34px;
  height: 90px;
  display: flex;
  justify-content: center;
}

.appointmentTimeCardContentTabs
  :global(.adm-jumbo-tabs-tab-description :nth-of-type(3)) {
  /* color: #0ebc6a; */
}

.appointmentTimeCardContentTabs
  :global(.adm-jumbo-tabs-tab-active .adm-jumbo-tabs-tab-description) {
  background: #0ebc6a !important;
  color: #ffffff;
}

.appointmentTimeCardContentTabs
  :global(
    .adm-jumbo-tabs-tab-active .adm-jumbo-tabs-tab-description :nth-of-type(3)
  ) {
  color: #ffffff !important;
}

.cardOne :global(.adm-card-body) {
  padding: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.appointmentTimeCardContentCard {
  padding: 0 16px !important;
  margin: 10px 10px 0 10px !important;
}

.appointmentTimeCardContentImg {
  width: 40px;
  height: 40px;
  margin: 20px 16px 20px 20px;
  border-radius: 50%;
}

.appointmentTimeCardContentMain {
  width: 52%;
  margin: 16px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appointmentTimeCardContentName {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #101010;
}

.appointmentTimeCardContentType {
  font-size: 13px;
  margin-bottom: 5px;
  color: #666666;
}

.appointmentTimeCardContentDetail {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.dataMain {
  margin: 10px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.appointmentTimeCardContentCardBottom {
  margin: 10px 10px 10px 10px;
  min-height: 300px;
}

.appointmentTimeCardContentCardBottom :global(.adm-capsule-tabs-header) {
  padding: 0;
  border-bottom: none;
}

.appointmentTimeCardContentCardBottomTitle {
  color: #101010;
  font-weight: bold;
  font-size: 15px;
  margin: 13px 0;
}

.bottomTime :global(.adm-capsule-tabs-tab-list) {
  width: calc(100vw - 42px);
  display: flex;
  scrollbar-width: none;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  flex-wrap: wrap;
}

.bottomTime :global(.adm-capsule-tabs-tab) {
  padding: 0 !important;
}

.bottomTime :global(.adm-capsule-tabs-tab-wrapper) {
  width: 100px;
  height: 50px;
  padding: 0 !important;
  display: flex;
  /* justify-content: space-around; */
}

.bottomTime :global(.adm-capsule-tabs-tab) {
  width: 100px;
  background: #f4f4f4;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
}

.bottomTime :global(.adm-capsule-tabs-tab-active) {
  background: #29be78;
}

.bottomTime :global(.adm-space) {
  width: 100%;
  /* overflow: hidden; */
}

.bottomButton {
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.bottomButtonText {
  width: 343px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}

.bottomButtons {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomButtonTexts {
  width: 331px;
  /* margin: 0 20px; */
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}

.dataTitle {
  width: 100%;
  height: 59px;
  color: #101010;
  font-size: 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.delectIcon {
  position: absolute;
  right: 10px;
  top: 19px;
  width: 24px;
  height: 24px;
  margin-left: 150px;
}

.calenderContent :global(.adm-calendar-title) {
  color: #0052d9 !important;
}

.calenderContent :global(.adm-calendar-cell) {
  justify-content: center !important;
}

.calenderContent :global(.adm-calendar-cell-today) {
  display: flex;
  justify-content: center !important;
  color: #101010;
}

.calenderContent :global(.adm-calendar-arrow-button) {
  color: #0ebc6a;
}

.calenderContent :global(.adm-calendar-cell-selected) {
  background-color: #0ebc6a !important;
  border-radius: 50% !important;
  color: #ffffff !important;
}

.noTimeListName {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomTime
  :global(
    .adm-capsule-tabs-tab-list
      > .adm-capsule-tabs-tab-wrapper
      > .adm-capsule-tabs-tab
  ):nth-of-type(3) {
  background: #ffffff;
}

.statusZero {
  color: #29be78;
}

.statusOne {
  color: #ffb145;
}

.statusTwo {
  color: #ccc;
}

.selectColor {
  color: #ffffff !important;
}
