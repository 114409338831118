.container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}

.container :global(.adm-card-body) {
    padding: 0 !important;
    width: 100%;
}

.adm-card-body {
    padding: 0;
}

.centerImg {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    position: relative;
    text-align: center;
    color: rgba(255, 0, 0, 1);
    font-size: 14px;
    border-radius: 8px;
    top: 10px;
}