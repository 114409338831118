.onlineAppointmentContent {
  position: relative;
  height: 100%;
  /* width: 100vh; */
  display: flex;
  /* padding-bottom: 20px; */
  flex-direction: column;
  align-items: center;
}
.backIcon {
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 9999;
}
.onlineAppointmentContent :global(.adm-tabs) {
  width: 100%;
  overflow: hidden;
}
.onlineAppointmentContent :global(.adm-tabs-content) {
  width: 100vw;
  height: 94%;
  /* margin-bottom: 0px; */
  overflow: scroll;
}

.onlineAppointmentContent :global(.adm-tabs-tab-active) {
  color: #41bb67;
}

.onlineAppointmentContent :global(.adm-tabs-tab-line) {
  background: #41bb67;
}

.onlineAppointmentContent :global(.adm-tabs-tab) {
  font-size: 14px;
  padding: 12px 0 10px;
}

.onlineAppointmentContent :global(.adm-tabs-tab-wrapper) {
  height: 40px;
}

.onlineAppointmentBottom {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.onlineAppointmentBottomTextOne {
  width: 166px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  margin-left: 10px;
}

.onlineAppointmentBottomTextTwo {
  width: 166px;
  height: 40px;
  color: #ffffff;
  background-color: #ffb145;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  margin-right: 10px;
}

.onlineAppointmentContent :global(.adm-card) {
  border-radius: 5px;
  margin: 10px 10px 0 10px;
}

.doctorCardContent {
  position: relative;
}

/* card部分 */
.doctorCardContent :global(.adm-card-body) {
  padding: 0 16px;
  /* width: 100%; */
  display: flex;
  align-items: center;
}

.timeCardContentTabsMain {
  background: #fff;
  margin: 10px 10px 0 10px;
  display: flex;
  border-radius: 5px;
}

.timeCardContentRightIcon {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeCardContentTabs {
  width: 88%;
  height: 110px;
  margin-right: 14px;
}

.timeCardContentRightIcon {
  width: 10%;
  /* border: 1px solid red; */
  box-shadow: -2px 0px 2px 0px rgba(252, 252, 252, 1);
}

.timeCardContentTabs :global(.adm-jumbo-tabs-tab-wrapper) {
  width: 34px;
  height: 90px;
  background: #f4f4f4;
  margin: 10px 6px 10px 10px;
  border-radius: 5px;
}

.timeCardContentTabs :global(.adm-jumbo-tabs-tab) {
  padding: 0;
  width: 100%;
}

.timeCardContentTabs :global(.adm-jumbo-tabs-tab-description) {
  color: #101010;
  width: 34px;
  height: 90px;
  display: flex;
  justify-content: center;
}

.timeCardContentTabs :global(.adm-jumbo-tabs-tab-description :nth-of-type(3)) {
  color: #0ebc6a;
}

.timeCardContentTabs
  :global(.adm-jumbo-tabs-tab-active .adm-jumbo-tabs-tab-description) {
  background: #0ebc6a !important;
  color: #ffffff;
}

.timeCardContentTabs
  :global(
    .adm-jumbo-tabs-tab-active .adm-jumbo-tabs-tab-description :nth-of-type(3)
  ) {
  color: #ffffff !important;
}

.timeCardContentTitle {
  padding: 0 16px;
  margin-bottom: 15px;
  font-size: 15px;
  margin-top: 14px;
  font-weight: bold;
}

.timeCardContent :global(.adm-card-body) {
  padding: 0 16px;
  /* width: 100%; */
  display: flex;
  align-items: center;
}

.doctorCardContentCard,
.timeCardContentCard {
  padding: 0 !important ;
}

.doctorCardContentImg,
.timeCardContentImg {
  width: 40px;
  height: 40px;
  margin: 20px 16px 20px 0;
}

.doctorCardContentMain,
.timeCardContentMain {
  width: 70%;
  margin: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.doctorCardContentName,
.timeCardContentName {
  width: 90%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #101010;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.doctorCardContentType,
.timeCardContentType {
  width: 90%;
  font-size: 13px;
  margin-bottom: 5px;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.doctorCardContentButton,
.timeCardContentButton {
  width: 60px;
  height: 24px;
  border-radius: 36px;
  color: #29be78;
  border: 1px solid #29be78;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noTimeCardContentButton {
  width: 60px;
  height: 24px;
  border-radius: 36px;
  color: #f58827;
  border: 1px solid #f58827;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nodDctorCardContentButton {
  width: 60px;
  height: 24px;
  border-radius: 36px;
  color: #f58827;
  border: 1px solid #f58827;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataMain {
  margin: 10px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottomButtons {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomButtonTexts {
  width: 331px;
  /* margin: 0 20px; */
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}

.dataTitle {
  width: 100%;
  height: 59px;
  color: #101010;
  font-size: 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.delectIcon {
  position: absolute;
  right: 10px;
  top: 19px;
  width: 24px;
  height: 24px;
  margin-left: 150px;
}

.calenderContent :global(.adm-calendar-title) {
  color: #0052d9 !important;
}

.calenderContent :global(.adm-calendar-cell-today) {
  display: flex;
  justify-content: center !important;
  color: #101010;
}

.calenderContent :global(.adm-calendar-arrow-button) {
  color: #0ebc6a;
}

.calenderContent :global(.adm-calendar-cell-selected) {
  background-color: #0ebc6a !important;
  border-radius: 50% !important;
  color: #ffffff !important;
}

.calenderContent :global(.adm-calendar-cell) {
  justify-content: center !important;
}

.noTimeListName {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusZero {
  color: #29be78;
}

.statusOne {
  color: #ffb145;
}

.statusTwo {
  color: #ccc;
}

.selectColor{
  color: #ffffff;
}