.doctorContent {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.backIcon {
  position: absolute;
  top: 45px;
  left: 20px;
  z-index: 9999;
}
.allDoctorDetail {
  height: 100%;
  overflow: scroll;
}

.doctorDetail {
  height: 89%;
  overflow: scroll;
}

.doctorContent :global(.adm-card-body) {
  padding: 0 !important;
}

.doctorDetail {
  margin: 10px 10px 0 10px;
  background: #ffffff;
  border-radius: 9px;
}

.header {
  padding: 18px 0 20px 0;
}

.headerContent {
  display: flex;
}

.headerContentImg {
  width: 50px;
  height: 50px;
  margin: 0 17px 0 30px;
  border-radius: 50%;
}

.headerContentProduce {
}

.headerContentProduceName {
  color: #101010;
  font-size: 18px;
  padding-bottom: 8px;
  font-weight: bold;
}

.headerContentProduceTwo {
  color: #101010;
  font-size: 14px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doctorDetailContent {
  padding: 0 16px 16px 16px;
  padding-bottom: 10px;
}

.doctorDetailContentMain {
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}
.doctorDetailContentTitleTwo {
  margin-top: 14px;
}

.doctorDetailContentTitle::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #0ebc6a;
  position: relative;
  left: -16px;
}

.doctorDetailContentTitleTwo::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #0ebc6a;
  position: relative;
  left: -16px;
}

.doctorDetailContentTitle,
.doctorDetailContentTitleTwo {
  font-size: 17px;
  color: #101010;
  padding: 0 0 14px 0;
}

.doctorDetailContentMain,
.doctorDetailContentMainTwo {
  font-size: 15px;
  color: #333333;
}

.doctorDetailBottom {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.doctorDetailBottomText {
  width: 343px;
  height: 40px;
  color: #ffffff;
  background-color: #0ebc6a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
}
