.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.top {
  height: 45px;
  flex: 0;
  border-bottom: solid 1px var(--adm-border-color);
  display: none;
}

.body {
  flex: 1;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  font-size: 14px;
}

.bottom {
  flex: 0;
  border-top: solid 1px var(--adm-border-color);
  padding: 6px 0;
}


.body :global(.adm-list-item-content-main > .adm-input-wrapper > .adm-input) {
  text-align: right;
}

.body :global(.adm-form-item-label) {
  width: 80px;
}

.body :global(.adm-form-item-label) {
  font-size: 16px;
}

/* 全局导航 */
.body :global(.adm-nav-bar) {
  background: #fff;
}

/* 全局列表 */
.body :global(.adm-list-item) {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.body :global(.adm-list-item-content) {
  border-bottom: none;
}

/* 全局搜索 */
.body :global(.adm-search-bar-input-box) {
  border-radius: 15px;
}

.body :global(.adm-search-bar) {
  padding: 10px;
  background: #fff;
}

.body :global(.adm-search-bar-suffix) {
  display: inline-block;
  padding: 5px 10px;
  background: #1677ff;
  border-radius: 3px;
}

.body :global(.adm-search-bar-suffix)>a {
  color: #fff;
}

/* 全局选项卡 */
.body :global(.adm-tabs) {
  height: 100%;
}

.body :global(.adm-tabs-content) {
  padding: 0;
  height: 100%;
}

.body :global(.adm-tabs-tab-list) {
  background: #fff;
}

/* 全局胶囊选项卡 */
.body :global(.adm-capsule-tabs) {
  height: 100%;
}

.body :global(.adm-capsule-tabs-content) {
  height: 100%;
  padding: 0;
}

.body :global(.adm-capsule-tabs-tab-wrapper) {
  padding: 5px;
  border-radius: 2px;
}

.body :global(.adm-capsule-tabs-tab) {
  padding: 5px;
  border-radius: 3px;
  background: #fff;
}

.body :global(.adm-capsule-tabs-tab-active) {
  background: #1677ff;
  color: #fff;
}
